<template>
    <el-dialog :visible.sync="showTcb" width="750px" class="dia" :show-close="false">
        <div class="tcbEject1" ref="tcb">
            <div class="teTitle">我要开发</div>
            <div class="teFromBox">
                <ul>
                    <li class="tfbLi">
                        <div class="tfLLeft">模式</div>
                        <div class="tfLRight">
                            <div class="modeList">
                                <div class="mlBox" @click="openMl">
                                    <p class="mlP">{{ conFrom.module }}</p>
                                    <i class="iconfont icon-xiala" :class="theIcon ? 'clickIcon' : 'clickIcon2'"></i>
                                </div>
                                <div class="mlList" :class="{ modelShow }">
                                    <ul>
                                        <li v-for="el in dataList" :key="el.id" class="mlLi" @click="getLi(el.category_title)">
                                            {{ el.category_title }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="tfbLi">
                        <div class="tfLLeft">行业</div>
                        <div class="tfLRight">
                            <input type="text" v-model="conFrom.industry" placeholder="必填项" />
                        </div>
                    </li>
                    <li class="tfbLi">
                        <div class="tfLLeft">预算</div>
                        <div class="tfLRight">
                            <input type="text" v-model="conFrom.budget" placeholder="必填项" />
                        </div>
                    </li>
                    <li class="tfbLi">
                        <div class="tfLLeft">手机</div>
                        <div class="tfLRight">
                            <input type="tel" v-model="conFrom.phone" placeholder="必填项" />
                        </div>
                    </li>
                    <li class="tfbLi">
                        <div class="tfLLeft">称呼</div>
                        <div class="tfLRight">
                            <input type="text" v-model="conFrom.name" placeholder="必填项" />
                        </div>
                    </li>
                    <li class="tfbLi">
                        <div class="tfLLeft">沟通时间</div>
                        <div class="tfLRight">
                            <!-- <el-time-picker
                                class="trEl"
                                :is-range="true"
                                v-model="conFrom.allow"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围"
                                value-format="HH:mm:ss"
                                :default-value="[new Date().getTime(), new Date().getTime() + 7200000]"
                                size="mini1"
                            >
                            </el-time-picker> -->
                            <div class="trEl">
                                <el-time-select
                                    placeholder="起始(时：分)"
                                    v-model="conFrom.allow[0]"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:01',
                                        end: '23:50',
                                    }"
                                >
                                </el-time-select>
                                <el-time-select
                                    placeholder="结束(时：分)"
                                    v-model="conFrom.allow[1]"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:01',
                                        end: '23:50',
                                        minTime: conFrom.allow[0],
                                    }"
                                >
                                </el-time-select>
                            </div>
                            <div class="tfAll">
                                <input type="checkbox" v-model="isCheck" />
                                <p class="taP">随时</p>
                            </div>
                        </div>
                    </li>
                    <li class="tfbLi">
                        <div class="tfLLeft">更多信息</div>
                        <div class="tfLRight">
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                maxlength="300"
                                placeholder="选填"
                                v-model="conFrom.more"
                            ></textarea>
                        </div>
                    </li>
                    <li class="tfbLi" v-loading="loading">
                        <div class="tfLLeft">附件上传</div>
                        <div class="tfLRight">
                            <button type="button" class="inFile" onclick="document.querySelector('#file>div').click()">
                                点击上传
                            </button>
                            <button class="inFile two" @click="clearUpload">清除</button>
                            <el-upload
                                id="file"
                                name="thumbnail"
                                :action="uploadUrl"
                                :on-success="success"
                                :before-upload="beforeUpload"
                                :on-error="error"
                                :show-file-list="false"
                                :limit="1"
                                :file-list="fileList"
                                accept=".jpg,.png,.doc,.docx,.pdf,.xls,.xlsx"
                                style="opacity: 0"
                                >上传</el-upload
                            >
                        </div>
                        <div class="prototypeName">{{ prototypeName }}</div>
                    </li>
                </ul>
            </div>
            <div class="teButton">
                <button class="btn1" @click="closeFrom">关闭</button>
                <!-- <input type="submit" class="btn2" /> -->
                <button class="btn2" @click="ok" :class="{ no: status }">提交</button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
export default {
    props: {
        page: {
            // 来自那个页面
            type: String,
            // 是否必填
            required: true,
        },
    },
    data() {
        return {
            // 表单信息
            conFrom: {
                module: "请点击选择模式",
                industry: "",
                budget: "",
                phone: "",
                name: "",
                allow: [
                    new Date().getHours() +
                        ":" +
                        (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()),
                    new Date().getHours() +
                        2 +
                        ":" +
                        (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()),
                ],
                more: "",
                channel: "",
                annex: "",
            },
            theIcon: false,
            showTcb: false,
            winX: null,
            winY: null,
            fileList: [],
            // --
            dataList: [],
            modelShow: false,
            // 上传地址
            uploadUrl: "",
            // 上传文件的原名
            prototypeName: "还未上传文件",
            // 上传状态
            loading: false,
            // 随时
            isCheck: false,
        };
    },
    created() {
        this.getData();
        this.uploadUrl = axios.defaults.baseURL + "/api/Project/upload";
        // this.uploadUrl = "https://vuapi.vu818.com" + "/api/Project/upload";
        this.conFrom.channel = this.page;
    },
    methods: {
        // 获取一级分类
        getData() {
            axios.get("/api/project/category_client").then(({ data }) => {
                this.dataList = data;
            });
        },
        // 显示表单
        showTcbs() {
            this.showTcb = true;
            window.addEventListener("scroll", this.gundong, { passive: false });
        },
        // 关闭表单
        closeFrom() {
            this.showTcb = false;
        },
        // 模式下拉框
        openMl() {
            if (this.modelShow) {
                this.modelShow = false;
                this.theIcon = false;
            } else {
                this.modelShow = true;
                this.theIcon = true;
            }
        },
        // 下拉框选择
        getLi(str = "") {
            this.modelShow = false;
            this.theIcon = false;
            this.conFrom.module = str;
        },
        // 点击其他区域关闭下拉菜单
        hideCon(e) {
            let tfr = document.querySelector(".tfLRight");
            if (tfr) {
                if (!tfr.contains(e.target)) {
                    this.theIcon = false;
                    this.modelShow = false;
                }
            }
            if (e.target === this.$el) {
                this.showTcb = false;
            }
        },
        // 提交表单
        async ok() {
            if (this.status) return;
            let { conFrom: form } = this;
            let { data } = await axios.post(
                "/api/mail/add",
                QueryString.stringify({
                    ...form,
                    allow: this.isCheck ? "随时" : form.allow.join("-"),
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg + `，恭喜你是今天第${data.count}位参与者`);
                this.conFrom = {
                    module: "请点击选择模式",
                    industry: "",
                    budget: "",
                    phone: "",
                    name: "",
                    allow: [
                        new Date().getHours() + ":" + new Date().getMinutes(),
                        new Date().getHours() + 2 + ":" + new Date().getMinutes(),
                    ],
                    more: "",
                    channel: this.page,
                    annex: "",
                };
                this.fileList = [];
                this.prototypeName = "还未上传文件";
                this.isCheck = false;
                this.closeFrom();
            }
        },
        // 禁止背景滚动
        gundong(e) {
            if (e.target !== this.$refs["tcb"] || e.target === this.$el) {
                e.preventDefault();
            }
        },
        // 上传成功
        success(url, file) {
            this.conFrom.annex = axios.defaults.baseURL + url;
            this.prototypeName = file.name;
            this.loading = false;
        },
        // 上传失败
        error(err, file, fileList) {
            this.$message.error("错误，请联系管理员");
            this.$message.error(err);
        },
        beforeUpload(file) {
            this.loading = true;
            // 上传限制
            const xz = file.size / 1024 / 1024 < 30;
            if (!xz) {
                this.$message.error("上传文件大小不能超过 30MB!");
                return false;
            }
            return true;
        },
        clearUpload() {
            this.fileList = [];
            this.prototypeName = "";
            this.conFrom.annex = "";
        },
    },
    computed: {
        status() {
            // 是否禁用
            for (const k in this.conFrom) {
                if (k == "industry" || k == "budget" || k == "phone" || k == "name") {
                    let str = this.conFrom[k].replace(" ", "");
                    if (!str.trim()) {
                        return true;
                    }
                }
                if (k == "module" && this.conFrom[k] == "请点击选择模式") return true;
            }
            return false;
        },
    },
};
</script>

<style lang="less" scoped>
.no {
    background: #ebeef5 !important;
    color: #c0c4cc !important;
    cursor: not-allowed !important;
}
.modelShow {
    z-index: 99 !important;
    transform: scaleY(1) !important;
    opacity: 1 !important;
}

.dia {
    /deep/.el-dialog {
        box-shadow: 1px 1px 5px 1px gray;
        border-radius: 30px;
    }
}
.tcbEject1 {
    width: 100%;
    height: 670px;
    position: relative;
    margin: auto;
    background-color: rgb(255, 255, 255);
    .teTitle {
        width: 95%;
        height: 50px;
        margin: 0 auto;
        line-height: 50px;
        font-size: 20px;
    }
    .teFromBox {
        width: 70%;
        height: 550px;
        margin: 0 auto;
        margin-top: 20px;
        color: white;
        ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .tfbLi {
                width: 100%;
                height: 40px;
                .tfLLeft {
                    min-width: 70px;
                    height: 40px;
                    font-size: 15px;
                    line-height: 40px;
                    text-align: center;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    float: left;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    box-sizing: content-box;
                }
                .tfLRight {
                    width: 420px;
                    height: 40px;
                    float: left;
                    input {
                        width: 95%;
                        height: 95%;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        padding-left: 20px;
                        border: none;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        font-size: 15px;
                        outline: none;
                        box-sizing: content-box;
                    }
                    .trEl {
                        width: 75%;
                        float: left;
                        text-align: center;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        border: none;
                        height: 40px;
                        line-height: 40px;
                        & > div {
                            width: 50%;
                        }
                    }
                    .tfAll {
                        // width: 25%;
                        flex: 1;
                        height: 40px;
                        line-height: 40px;
                        float: left;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        margin-left: 10px;
                        color: gray;
                        padding: 0 20px;
                        input {
                            // margin-left: 20px;
                            margin-top: 10px;
                            width: 20px;
                            height: 20px;
                            float: left;
                        }
                        .taP {
                            float: left;
                        }
                    }
                    .modeList {
                        width: 420px;
                        height: 40px;
                        float: left;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border: 1px solid white;
                        box-sizing: border-box;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        position: relative;
                        .mlBox {
                            width: 100%;
                            height: 40px;
                            .mlP {
                                width: 90%;
                                height: 100%;
                                text-align: center;
                                line-height: 40px;
                                color: gray;
                                float: left;
                            }
                            .icon-xiala {
                                width: 25px;
                                height: 40px;
                                line-height: 40px;
                                text-align: center;
                                color: black;
                                font-size: 20px;
                                float: right;
                                margin-right: 5px;
                                transition: 0.2s;
                            }
                            .clickIcon {
                                transform: rotate(180deg);
                            }
                            .clickIcon2 {
                                transform: rotate(0deg);
                            }
                        }
                        .mlList {
                            width: 420px;
                            // height: 200px;
                            position: absolute;
                            top: 50px;
                            border-radius: 10px;
                            background-color: white;
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            z-index: -1;
                            transition: 0.2s;
                            opacity: 0;
                            transform: scaleY(0);
                            transform-origin: 50% 0;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-around;
                                .mlLi {
                                    width: 100%;
                                    height: 30px;
                                    line-height: 30px;
                                    padding-left: 15px;
                                    color: black;
                                    font-size: 14px;
                                }
                                .mlLi:hover {
                                    background-color: gainsboro;
                                }
                            }
                        }
                    }
                }
                .prototypeName {
                    float: left;
                    color: #999;
                    display: inline;
                    line-height: 40px;
                    margin-left: 10px;
                }
            }
            .tfbLi:nth-child(6) {
                .el-range-input {
                    border-radius: 10px;
                    box-shadow: none;
                }
            }
            .tfbLi:nth-child(7) {
                width: 100%;
                height: 150px;
                .tfLLeft {
                    min-width: 70px;
                    height: 100%;
                    line-height: 150px;
                }
                .tfLRight {
                    width: 420px;
                    height: 100%;
                    float: left;
                    border-radius: 20px;
                    textarea {
                        width: 100%;
                        height: 100%;
                        padding-top: 10px;
                        padding-left: 10px;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        border: none;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        outline: none;
                        font-size: 15px;
                        box-sizing: border-box;
                    }
                }
            }
            .tfbLi:nth-child(8) {
                .tfLRight {
                    width: 160px;
                    height: 40px;
                    float: left;
                    .inFile {
                        display: inline-block;
                        cursor: pointer;
                        width: 50%;
                        height: 100%;
                        border: none;
                        color: gray;
                        background-color: white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                    }
                    .two {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }
        }
    }
    // 关闭提交
    .teButton {
        width: 250px;
        height: 40px;
        float: right;
        .btn1,
        .btn2 {
            width: 80px;
            height: 40px;
            line-height: 40px;
            border-radius: 10px;
            font-size: 18px;
            cursor: pointer;
            background-color: white;
            border: none;
        }
        .btn2 {
            margin-left: 40px;
            background: linear-gradient(to right, #7781f1, #7781f1e0);
            color: white;
        }
    }
}
</style>
